// import Body from '../components/Body'
import Drivers from '../components/Drivers'
import Introduction from "../components/Introduction"
import Header from "../components/Header"
import ScrollingImages from '../components/ScrollingImages'

export default function HomePage() {
    return (
        <>
            <Header />
            <Introduction/>
            <h1 style={{ textAlign: 'center' }}>Drivers</h1>
            <Drivers/>
            <h1 style={{ textAlign: 'center' }}>Places we frequent!</h1>
            <ScrollingImages/>
        </>
    );
  }