import { useState, useEffect } from 'react';
// import Body from '../components/Body'
import { supabase } from '../components/SupabaseClient';
import { useParams } from 'react-router-dom';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';

export default function HomePage() {

    const [driver, setDriver] = useState(null);
    const { drivername } = useParams();

    useEffect(() => {
        (async () => {
            const { data, error } = await supabase.from("drivers").select().eq("name", drivername).single();
            if (error) {
                console.log(error);
            }
            else if (data) {
                setDriver(data);
            }
        })();
    }, [drivername]);



    return (
        <>
            {driver === undefined ?
                <p>Could not retrieve driver.</p>
                :
                <>
                    {driver === null ?
                        <Spinner animation="border" />
                        :
                        <Stack direction="horizontal" gap={4} className='driver_info_stack'>
                            <Image src={driver.profile_picture + '&s=128'} roundedCircle />
                            <div>
                                <h1>{driver.name}</h1>
                                {driver.bio && <h5>{driver.bio}</h5>}
                                <h3><a href={`tel:${driver.phone_number}`}>Call {driver.phone_number}</a></h3>
                            </div>
                        </Stack>
                    }
                </>
            }
        </>
    );
}