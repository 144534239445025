import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// import Stack from 'react-bootstrap/Stack';


export default function Driver({ driver }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/driver/' + driver.name);
    };

    return (
        // <Card>
        //     <Card.Img variant="top" src={driver.profile_picture}/>
        //     <Card.Body>
        //         <Card.Title>{driver.name}</Card.Title>
        //         <Card.Text>Crazy Turtle Taxi of {driver.region} County</Card.Text>
        //         <Button variant="primary" onClick={handleClick}>View Profile</Button>
        //     </Card.Body>
        // </Card>

        //     <Card>
        //     <Row className="no-gutters">
        //       <Col md={4}>
        //         <Card.Img 
        //           variant="left" 
        //           src="https://via.placeholder.com/150" 
        //           alt="Card image" 
        //         />
        //       </Col>
        //       <Col md={20}>
        //         <Card.Body>
        //           <Card.Title>Card Title</Card.Title>
        //           <Card.Text>
        //             This is a wider card with supporting text below as a natural lead-in to additional content. 
        //             This content is a little bit longer.
        //           </Card.Text>
        //         </Card.Body>
        //       </Col>
        //     </Row>
        //   </Card>


        <div className="horizontal-card">
            <img
                src={driver.profile_picture}
                alt=""
                className="card-image"
            />
            <div className="card-content">
                <h3>{driver.name}</h3>
                <p>Crazy Turtle Taxi of {driver.region} County</p>
                <Button variant="primary" onClick={handleClick}>View Profile</Button>
            </div>
        </div>

    );
}