import React from 'react';
import { Carousel } from 'react-bootstrap';
import { supabase } from './SupabaseClient';
import Spinner from 'react-bootstrap/Spinner';
import { useEffect, useState } from "react";

const ScrollingImages = () => {
    const [imageUrls, setImageUrls] = useState(null);

    useEffect(() => {
        async function fetchImages() {
            const { data, error } = await supabase.storage
                .from('scrolling_images')
                .list();
            // console.log("Data:", data);

            if (error) {
                console.error('Error fetching image URLs:', error);
            } else {
                const urls = data.map(file =>
                    "https://auxyzfhoazunhazmrvjg.supabase.co/storage/v1/object/public/scrolling_images/" + file.name
                );
                setImageUrls(urls);
                console.log(urls);
            }
        }
        fetchImages();
    }, []);

    return (
        <>
            {imageUrls === undefined ?
                <p>Could not retrieve images.</p>
                :
                <>
                    {imageUrls === null ?
                        <Spinner animation="border" />
                        :
                        <Carousel className='scroller'>
                            {imageUrls.map((url, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        className="d-block w-100"
                                        src={url}
                                        alt={`Slide ${index}`}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    }
                </>
            }
        </>
    );
};

export default ScrollingImages;
